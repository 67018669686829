export const getEventSoundConfig = () => {
  return {
    effectSounds: [
      {
        name: 'emoji',
        src: {
          mp3: 'https://assets.modulr.dmdr.io/shared/howler/mp3/emojis.mp3',
          webm: 'https://assets.modulr.dmdr.io/shared/howler/webm/emojis.webm',
        },
        loop: false,
      },
      {
        name: 'teleport',
        src: {
          mp3: 'https://assets.modulr.dmdr.io/shared/howler/mp3/teleport.mp3',
          webm: 'https://assets.modulr.dmdr.io/shared/howler/webm/teleport.webm',
        },
        loop: false,
      },
      {
        name: 'walkForward',
        src: {
          mp3: 'https://assets.modulr.dmdr.io/shared/howler/mp3/footsteps1.mp3',
          webm: 'https://assets.modulr.dmdr.io/shared/howler/webm/footsteps1.webm',
        },
        loop: true,
      },
      {
        name: 'walkBackward',
        src: {
          mp3: 'https://assets.modulr.dmdr.io/shared/howler/mp3/footsteps-bkwd.mp3',
          webm: 'https://assets.modulr.dmdr.io/shared/howler/webm/footsteps-bkwd.webm',
        },
        loop: true,
      },
      {
        name: 'clickUi',
        src: {
          mp3: 'https://assets.modulr.dmdr.io/shared/howler/mp3/select.mp3',
          webm: 'https://assets.modulr.dmdr.io/shared/howler/webm/select.webm',
        },
        loop: false,
      },
    ],
  };
};
