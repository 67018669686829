import React, { useEffect, useState } from 'react';
import { useActivityStore } from 'services/ActivityService';
import { useContentStore } from 'services/ContentService';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import CloseButton from 'common/components/CloseButton';
import Content from './Content';
import {
  Background,
  CloseFullscreen,
  Container,
  StyledCoverBackground,
  StyledFullScreenTitleBar,
  StyledTransitionBar,
  StyledTransitionBarContainer,
} from './styles';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { usePostStore } from 'three/Renderer/store';
import { useResourceStore } from 'services/ResourceService';
import IFrame from './Content/IFrame';
import { useTheaterContent } from 'utilities/hooks';
import { useHowlerStore } from 'services/HowlerService';

export default function FullscreenContent() {
  const content = useContentStore(state => state.activeContent);
  const activeContent = useContentStore(state => state.activeContent);
  const isZoom = activeContent && activeContent.type === CONTENT_TYPE.ZOOM;
  const isProduct = activeContent && activeContent.type === CONTENT_TYPE.PRODUCT;
  const theaterContent = useTheaterContent(!isProduct && content);
  const [contentVisible, setContentVisible] = useState(false);
  const fullscreenContentInFocus = content && (content.type.isFullscreen || content.contentType.isFullscreen);
  const isChatOpen = useChatStore(state => state.isActive);
  const isShiftedByVideoChat = useChatStore(state => state.isShiftedByVideoChat);
  const enableScrollbar = useWindowStore(state => state.height < 600);
  const isMobile = useWindowStore(state => state.isMobile);

  const fadeInSound = useHowlerStore(state => state.fadeInSound);

  const vh = useWindowStore(state => state.vh);
  const activeIframeResource = useResourceStore(state => {
    if (state.activeResource && state.activeResource.type === CONTENT_TYPE.IFRAME.id) {
      return state.activeResource;
    } else {
      return null;
    }
  });

  const handleCloseBackButtonClick = () => {
    const { clearActiveResource, activeResource } = useResourceStore.getState();
    if (activeResource) {
      clearActiveResource();
    } else {
      fadeInSound();
      useContentStore.getState().clearActiveContent();
    }
  };

  useEffect(() => {
    if (!fullscreenContentInFocus) setContentVisible(false);
    if (fullscreenContentInFocus) {
      useActivityStore.getState().enterContent(content);
      useChatStore.getState().switchContent();
      const t = setTimeout(() => {
        usePostStore.setState({ isPaused: true });
      }, 1000);
      return () => {
        clearTimeout(t);
        usePostStore.setState({ isPaused: false });
        useActivityStore.getState().leaveContent();
        useChatStore.getState().switchContent();
      };
    }
  }, [fullscreenContentInFocus]);

  const getTitleLabel = () => {
    if (content.type.id === CONTENT_TYPE.PRODUCT.id) {
      return 'close view';
    }
    if (content.location == 'stage01') {
      return theaterContent.title;
    }
    return content.headline;
  };

  if (!!content && content.location == 'stage01' && !!theaterContent.content) {
    content.url = theaterContent.content;
  }

  return (
    <>
      <StyledTransitionBarContainer
        vh={vh}
        isChatOpen={isChatOpen && !isZoom}
        isShiftedByVideoChat={isShiftedByVideoChat}
        show={fullscreenContentInFocus}
      >
        <StyledCoverBackground vh={vh} show={fullscreenContentInFocus} />
        {[1, 2, 3].map(item => {
          return (
            <StyledTransitionBar
              key={item}
              index={item}
              show={fullscreenContentInFocus}
              isChatOpen={isChatOpen}
              isShiftedByVideoChat={isShiftedByVideoChat}
            />
          );
        })}
      </StyledTransitionBarContainer>
      <Background
        show={fullscreenContentInFocus}
        hasChat={!isZoom}
        isChatOpen={isChatOpen && !isZoom}
        isShiftedByVideoChat={isShiftedByVideoChat}
        isMobile={isMobile}
      >
        <StyledFullScreenTitleBar show={fullscreenContentInFocus}>
          <CloseFullscreen show={fullscreenContentInFocus} onClick={handleCloseBackButtonClick}>
            <CloseButton isBackButton={false} size={30} noBackground center />
          </CloseFullscreen>
          {content && <span>{getTitleLabel()}</span>}
        </StyledFullScreenTitleBar>
        {fullscreenContentInFocus && (
          <Container vh={vh} show={contentVisible} enableScrollbar={enableScrollbar}>
            {!activeIframeResource && (
              <Content
                content={content}
                onFinishInitialize={() => {
                  setContentVisible(true);
                }}
              />
            )}
            {activeIframeResource && (
              <IFrame
                attributes={{
                  src: activeIframeResource.url,
                  allow:
                    'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; camera; microphone; fullscreen',
                  autoPlay: true,
                }}
              />
            )}
          </Container>
        )}
      </Background>
    </>
  );
}
