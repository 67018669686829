import { BlockTitle, LightSwitchOptionContainer, ProfileBlock, ProfileInfo, ProfileInfoTitle } from '../styles';
import React, { useEffect, useState } from 'react';
import { Button } from 'common/components/Button';
import ToggleButton from 'common/components/ToggleButton';
import Toast from 'common/components/Toast';
import EditPassword from '../EditPassword';
import ImageUploadButton from '../ImageUploadButton';
import ProfilePicture from '../ProfilePicture';
import HBFLayout from 'common/components/HBFLayout';
import { useUserStore } from 'services/UserService';
import { useNotificationStore } from 'services/NotificationService';
import { Input } from 'common/components/Input';
import { marginTopAndButton } from '../../../../styles';
import { closeButtonSize } from '../../../styles';
import { useTranslate } from 'services/TranslationService/hooks';
import VeTextLink from 'common/components/VeTextLink';
import useIsQuickRegistration from 'uiLivi/components/Menu/SubMenu/ProfileMenu/useIsQuickRegistration';

function EditProfilePicture({ hasImage }) {
  const translate = useTranslate();
  return (
    <>
      <div style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
        <BlockTitle>{translate('profile.picture.headline') || 'Profile Picture'}</BlockTitle>
      </div>
      <div style={{ width: '100%', display: 'flex', marginTop: '5px', justifyContent: 'space-evenly' }}>
        <ImageUploadButton
          onImageSelected={buffer => {
            useUserStore.getState().updateProfilePicture(buffer);
          }}
        />

        <VeTextLink
          as={'button'}
          disabled={!hasImage}
          veStyle
          onClick={() => {
            useUserStore.getState().deleteProfilePicture();
          }}
          data-track-category="Profile"
          data-track-action={'DeletePicture'}
          small
          style={{ width: '50%', marginLeft: '4px', borderWidth: '1px' }}
        >
          {translate('profile.picture.delete') || 'Delete'}
        </VeTextLink>
      </div>
    </>
  );
}

function EditView({ user, setEditMode, tabs, updateSize }) {
  const isQuickRegistration = useIsQuickRegistration(user);
  const [forename, setForename] = useState(user.forename);
  const [surname, setSurname] = useState(user.surname);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCopy, setNewPasswordCopy] = useState('');
  const [company, setCompany] = useState(user.company);
  const [title, setTitle] = useState(user.title);
  const [country, setCountry] = useState(user.country);
  const [state, setState] = useState(user.state);
  const [linkedInUrl, setLinkedInUrl] = useState(user.linkedInUrl);
  const [hasNotifications, setHasNotifications] = useState(user.hasNotifications);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const editPasswordContext = {
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    newPasswordCopy,
    setNewPasswordCopy,
  };

  const translate = useTranslate();

  const [editPassword, setEditPassword] = useState(false);
  useEffect(updateSize, [editPassword]);

  useEffect(() => {
    let enable = true;
    if (currentPassword.length > 0 || newPassword.length > 0 || newPasswordCopy.length > 0) {
      enable = currentPassword.length >= 6 && newPassword.length >= 6 && newPasswordCopy === newPassword;
    }
    setSubmitEnabled(enable);
  }, [currentPassword, newPassword, newPasswordCopy]);

  const handleInputChange = (e, handler) => {
    const value = e.nativeEvent.target.value;
    handler(value);
  };

  const handleUpdate = async () => {
    const values = {
      forename,
      surname,
      company,
      title,
      country,
      state,
      hasNotifications,
      linkedInUrl,
    };

    if (currentPassword && newPassword) {
      values.password = currentPassword;
      values.newPassword = newPassword;
    }

    try {
      await useUserStore.getState().updateProfile(values);
      setEditMode(false);
    } catch (error) {
      let toast;
      switch (error.message) {
        case 'FORENAME_TOO_SHORT':
          toast = (
            <Toast
              headline={translate('profile.error.firstname.headline') || 'The first name is too short'}
              text={translate('profile.error.firstname.text') || 'Please enter your first name.'}
              level={'error'}
              position={'bottom'}
            />
          );
          break;
        case 'SURNAME_TOO_SHORT':
          toast = (
            <Toast
              headline={translate('profile.error.lastname.headline') || 'The last name is too short'}
              text={translate('profile.error.lastname.text') || 'Please enter your last name.'}
              level={'error'}
              position={'bottom'}
            />
          );
          break;
        case 'NOT_VALID_LINKEDIN_URL':
          toast = (
            <Toast
              headline={translate('profile.error.linkedin.headline') || 'Your LinkedIn URL is not valid.'}
              text={
                translate('profile.error.linkedin.text') ||
                'Please enter a valid url (https://linkedin.com or https://www.linkedin.com)'
              }
              level={'error'}
              position={'bottom'}
            />
          );
          break;
        case 'PASSWORD_TOO_SHORT':
          toast = (
            <Toast
              headline={translate('profile.error.passwordshort.headline') || 'The password is too short'}
              text={translate('profile.error.passwordshort.text') || 'Please choose a longer password.'}
              level={'error'}
              position={'bottom'}
            />
          );
          break;
        case 'WRONG_PASSWORD':
        default:
          toast = (
            <Toast
              headline={translate('profile.error.wrongpassword.headline') || 'The password does not match'}
              text={translate('profile.error.wrongpassword.text') || 'Please check your current password.'}
              level={'error'}
              position={'bottom'}
            />
          );
          break;
      }
      useNotificationStore.getState().addNotification(toast);
    }
  };

  const sharedProps = {
    border: true,
    margin: 'tight',
  };

  const body = (
    <>
      <ProfileBlock highlighted centered>
        <ProfilePicture user={user} />
        <EditProfilePicture hasImage={user.profilePicture != null} />
      </ProfileBlock>
      <ProfileBlock highlighted>
        <BlockTitle>{translate('avatar.edit.general') || 'General'}</BlockTitle>
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.firstname') || 'First name'}
          value={forename}
          onChange={e => {
            handleInputChange(e, setForename);
          }}
        />
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.surname') || 'Last name'}
          value={surname}
          onChange={e => {
            handleInputChange(e, setSurname);
          }}
        />
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.title') || 'Title'}
          value={title}
          onChange={e => {
            handleInputChange(e, setTitle);
          }}
        />
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.company') || 'Company'}
          value={company}
          onChange={e => {
            handleInputChange(e, setCompany);
          }}
        />
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.company') || 'Country'}
          value={country}
          onChange={e => {
            handleInputChange(e, setCountry);
          }}
        />
        <Input
          {...sharedProps}
          placeholder={translate('register.placeholder.state') || 'State'}
          value={state}
          onChange={e => {
            handleInputChange(e, setState);
          }}
        />

        <div style={{ height: '30px' }} />

        <>
          <BlockTitle>{translate('profile.contact') || 'Contact'}</BlockTitle>
          <ProfileInfoTitle>{translate('profile.social') || 'Social Media'}</ProfileInfoTitle>
          <Input
            {...sharedProps}
            placeholder={translate('profile.social.placeholder') || 'LinkedIn URL'}
            value={linkedInUrl || ''}
            onChange={e => {
              handleInputChange(e, setLinkedInUrl);
            }}
          />
        </>

        <div style={{ height: '15px' }} />

        {!isQuickRegistration && (
          <>
            <BlockTitle>{translate('profile.account') || 'Account'}</BlockTitle>
            <ProfileInfoTitle>{translate('profile.mail') || 'Email'}</ProfileInfoTitle>
            <ProfileInfo>{user.email}</ProfileInfo>

            {!editPassword ? (
              <>
                <ProfileInfoTitle>{translate('profile.password.headline') || 'Password'}</ProfileInfoTitle>
                <ProfileInfo> ● ● ● ● ● ● ●</ProfileInfo>
                <VeTextLink
                  onClick={() => {
                    setEditPassword(!editPassword);
                  }}
                >
                  {translate('profile.password.edit') || 'Edit password'}
                </VeTextLink>
              </>
            ) : (
              <EditPassword {...editPasswordContext} />
            )}
          </>
        )}

        <div style={{ height: '30px' }} />

        <BlockTitle>{translate('profile.settings.headline') || 'Profile Settings'}</BlockTitle>
        <LightSwitchOptionContainer>
          <ProfileInfo>{translate('profile.notifications.notification') || 'Enable Notifications'}</ProfileInfo>
          <ToggleButton
            active={hasNotifications}
            disabled={false}
            onToggle={isActive => {
              setHasNotifications(isActive);
            }}
          />
        </LightSwitchOptionContainer>
      </ProfileBlock>
    </>
  );

  const footer = (
    <Button
      data-track-category="Profile"
      data-track-action={'Save'}
      fullWidth
      accent
      veStyle
      disabled={!submitEnabled}
      onClick={handleUpdate}
    >
      {translate('profile.edit.save') || 'Save'}
    </Button>
  );

  return (
    <HBFLayout header={tabs} body={body} footer={footer} fullHeightMinus={marginTopAndButton * 2 + closeButtonSize} />
  );
}

export default EditView;
