import io from 'socket.io-client';
import schemapack from './schemapack-parser';
import create from 'utilities/zustand/create';
import { DEVELOPMENT, LOCAL, PRODUCTION, useBuildStore } from 'services/BuildService';
import { useEventStore } from 'services/EventService';

const qs = require('query-string');

const ENDPOINTS = {
  [LOCAL]: 'http://localhost:5000/',
  [DEVELOPMENT]: 'https://develop.hub.modulr.dmdr.io/',
  [PRODUCTION]: 'https://hub.modulr.dmdr.io/',
};

const INTEGRATION_TARGETS = {
  [LOCAL]: ENDPOINTS[LOCAL],
  [DEVELOPMENT]: ENDPOINTS[DEVELOPMENT],
  [PRODUCTION]: ENDPOINTS[PRODUCTION],
};

let socket = null;
export const useSocketStore = create((set, get) => ({
  url: '',
  hubId: null,
  path: '',

  selectEndpoint: hubId => {
    const { hubUrl: urlHubUrl, hubId: urlHubId } = qs.parse(window.location.search);
    const { integration } = useBuildStore.getState();
    const url = new URL(ENDPOINTS[integration]);
    if (urlHubId !== undefined) {
      const path = `${urlHubId}/`;
      set({ hubId: urlHubId, path });
    } else if (hubId !== null) {
      const path = `${hubId}/`;
      set({ hubId, path });
    } else {
      set({ hubId: null, path: '' });
      // eslint-disable-next-line no-console
      console.error('Hub instancing not available. Using default endpoint.');
    }

    if (integration === LOCAL) {
      url.port = `${parseInt(url.port) + parseInt(hubId)}`;
    }

    if (urlHubUrl) {
      set({ url: new URL(`${urlHubUrl}`).toString() });
    } else {
      set({ url: url.toString() });
    }
  },

  getRestUrl: () => {
    // const { integration } = useBuildStore.getState();
    // const debugSocketTarget = debugStore.getSocketTarget();
    // const url = debugSocketTarget === 'AUTO' ? INTEGRATION_TARGETS[integration] : ENDPOINTS[debugSocketTarget];
    // return url;
    const { url, path } = get();
    return `${url}${path}`;
  },

  connect: () => {
    const eventId = useEventStore.getState().event.id;
    if (socket) {
      try {
        socket.removeAllListeners();
        socket.disconnect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }

    // const { integration } = useBuildStore.getState();
    // const debugSocketTarget = debugStore.getSocketTarget();
    // const url = debugSocketTarget === 'AUTO' ? INTEGRATION_TARGETS[integration] : ENDPOINTS[debugSocketTarget];
    // set({ url });
    const { url, hubId } = get();
    const event = useEventStore.getState().event;
    socket = io(url + `?protocol=v6&eventId=${event.id}&eventShortName=${event.shortname}`, {
      path: hubId === null ? null : `/${hubId}/socket.io/`,
      parser: schemapack,
      reconnectionAttempts: 0,
      reconnectionDelay: 500,
    });
    return socket;
  },

  disconnect: () => {
    // eslint-disable-next-line no-console
    console.log('SocketService.disconnect');
    socket.removeAllListeners();
    socket.disconnect();
    socket = null;
  },

  getSocket: () => socket,
}));
