import React, { useEffect, useMemo, useState } from 'react';
import { useContentStore } from 'services/ContentService';
import Screen from './Screen';

export default function Contents({ contentGroup, visible }) {
  const contents = useContentStore(state => state.contents);

  const [contentComponents, setContentComponents] = useState(null);

  useEffect(() => {
    if (!contents || contents.length === 0) return;
    const result = [];
    contentGroup.children.forEach(o => {
      // remove "_content_screen" from name
      const nameElements = o.name.split('_');
      nameElements.pop();
      nameElements.pop();
      const location = nameElements.join('_');
      const content = contents.find(a => a.location === location);
      if (!content) {
        // eslint-disable-next-line no-console
        console.warn('No content for ', location);
        return;
      }
      if (!content.type) {
        // eslint-disable-next-line no-console
        console.warn('No Type for content', content);
        return;
      }
      result.push(<Screen name={content.location} key={content.location} object={o} content={content} />);
    });
    setContentComponents(result);
  }, [contentGroup, contents]);

  if (!contentComponents) return null;
  return <group name={'Contents'}>{visible && contentComponents}</group>;
}
