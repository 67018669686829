import { Headline, Subline } from '../../styles';
import React from 'react';
import { Input } from 'common/components/Input';
import { TextArea } from './styles';

const MessageForm = ({ open, formState }) => {
  const { name } = open;
  const [form, setForm] = formState;
  const { mail, phone, message } = form;

  const handleInputChange = (e, key) => {
    const value = e.nativeEvent.target.value;
    const newForm = { ...form };
    newForm[key] = value;
    setForm(newForm);
  };

  return (
    <>
      <Headline>Leave a message for {name}</Headline>
      <Subline>*required</Subline>
      <form>
        <Input
          pattern=".+@beststartupever\.com"
          type="email"
          placeholder="Email *"
          value={mail}
          onChange={e => handleInputChange(e, 'mail')}
          margin="tight"
          required
        />
        <Input
          type="phone"
          placeholder="Phonenumber *"
          value={phone}
          onChange={e => handleInputChange(e, 'phone')}
          margin="tight"
          required
        />
        <TextArea
          maxLength="255"
          margin="tight"
          rows={5}
          placeholder="Message"
          onChange={e => handleInputChange(e, 'message')}
        />
      </form>
    </>
  );
};

export default MessageForm;
