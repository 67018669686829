import { Button } from 'common/components/Button';
import { useHistory } from 'react-router-dom';
import { useDistrictStore } from 'services/DistrictService';

const JoinTalkButton = ({ children, small, accent, accept }) => {
  const history = useHistory();
  const districs = useDistrictStore(state => state.districts);

  const onClick = () => {
    const roomId = districs.find(d => d.room.includes('auditorium')).room;
    history.push(`/play/${roomId}`);
    accept();
  };

  return (
    <Button small={small} accent={accent} onClick={onClick}>
      {children}
    </Button>
  );
};

export default JoinTalkButton;
