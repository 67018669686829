import React, { useEffect, useRef } from 'react';
import { MeshStandardMaterial } from 'three';

export default function ScreenGlass({ object }) {
  const meshRef = useRef();

  useEffect(() => {
    meshRef.current.material = new MeshStandardMaterial({
      color: '#000000',
      transparent: true,
      opacity: 0.05,
      roughness: 0.2,
      depthWrite: false,
    });
  }, []);

  return <mesh name={'ScreenGlass'} geometry={object.geometry} ref={meshRef} position={[0, 0, 0.01]} />;
}
